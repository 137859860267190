<template>
  <div class="ip-telephony">
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.fullPath"></component>
      </keep-alive>
    </router-view>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
import axios from "axios";

export default {
  components: {
    HeroBlock,
    FormCommon,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
    };
  },
  methods: {
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image;
        this.icon = response.data.data[0].icon[0].url;
        this.title = response.data.data[0].title;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBanner();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";
</style>
